<template>
  <div id="development">
    <div class="container">
      <Component v-for="(component, index) in children"
        v-bind:key="index"
        v-bind:is="component.name"
        v-bind:props="component.props" />
    </div>
  </div>
</template>

<script>
  export default {
    computed: {
      children() {
        return this.$store.getters["system/pages"].find(page => page.name == "Development").children;
      }
    }
  };
</script>